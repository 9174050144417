import React, { ReactNode, useRef } from "react";
import "./navbar.scss";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";
import { select_is_navbarBarActive, select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setNavbarOpen } from "../../redux/callbacksState/callbacksState.actions";
import MenuBtnIcon from "../../assets/menuBtn.svg";
import CloseBtnIcon from "../../assets/closeBtn.svg";
import Container from "../container/container";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";
import LogoImg from "./../../assets/logo.png";
import LogoDevImg from "./../../assets/PIWOS-deweloper-logo.svg";
import FbIconImg from "./../../assets/fbIcon.svg";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
  isDev?: boolean;
}

const Navbar: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const firstLink = useRef<any>();

  const is_navbarBarActive = useSelector(select_is_navbarBarActive, shallowEqual);
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  const setNavExpanded = (isOpen: boolean) => {
    dispatch(setNavbarOpen(isOpen));
  }

  return (
    <>
      <div className={`navbarBarWrapper`}>
        <div className={`navbarBar`}>
          <Container>
            <Grid>
              <Row>
                <Col size={6}>
                  <div className={`navbarLogo`} onClick={() => setNavExpanded(false)}>
                    {props.isDev ?
                      <TopLink to={RoutePath.DEVELOPER}>
                        <img src={LogoDevImg} alt="LogoImg" />
                      </TopLink>
                      :
                      <TopLink to={RoutePath.COMPANY}>
                        <img src={LogoImg} alt="LogoImg" />
                      </TopLink>
                    }
                  </div>
                </Col>
                <Col size={6}>
                  <div className={`menuBtn${is_navbarOpenActive ? " isActive" : ""}${is_navbarBarActive ? " navbarbarActive" : ""}`} onClick={() => setNavExpanded(!is_navbarOpenActive)}>
                    {is_navbarOpenActive ?
                      <img src={CloseBtnIcon} className="closeBtn" alt="" />
                      : <img src={MenuBtnIcon} alt="" />}
                  </div>
                </Col>
              </Row>
            </Grid>
          </Container>
        </div>
        <div className="mobileMenuWrapper">
          <div className={`mobileSideMenuOverlay ${is_navbarOpenActive ? "isActive" : ""}`} onClick={() => setNavExpanded(false)}></div>
          <div className={`mobileSideMenu ${is_navbarOpenActive ? "isActive" : ""}`} >
            <div className={`mobileSideMenuInsetWrapper isScrolled`} onClick={() => setNavExpanded(false)} ref={firstLink}>
              <TopLink to={RoutePath.COMPANY}>
                <div className="linkLarge">PIWIOŚ</div>
              </TopLink>
              <TopLink to={RoutePath.DEVELOPER}>
                <div className="linkLarge">DEWELOPER</div>
              </TopLink>
              <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                <div className="linkLarge">INWESTYCJE</div>
              </TopLink>
              <a href="http://facebook.com" className="fbLink" target="_blank" rel="noopener noreferrer">
                <img src={FbIconImg} alt="Piwioś" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;