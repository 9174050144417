import React, { useRef } from "react";
import Container from "../container/container";
import MainHeader from "../main-header/main-header";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import "./references-section.scss";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import ReferencjaImg from './../../assets/referencja1.png';
import ReferencjaImg1 from './../../assets/olesnica.png';
import ReferencjaImg2 from './../../assets/miedzyzborz.png';
import ReferencjaImg3 from './../../assets/ekowod.png';
import ReferencjaImg4 from './../../assets/olawa.jpg';
import ReferencjaImg5 from './../../assets/zwig.png';
import ReferencjaImg6 from './../../assets/namyslow.png';
import ReferencjaImg7 from './../../assets/pokoj.png';
import ReferencjaImg8 from './../../assets/Galewice.jpg';
import ReferencjaImg9 from './../../assets/SGK.png';
import ReferencjaImg10 from './../../assets/dobroszyce.png';
import ReferencjaImg11 from './../../assets/wodociagi.png';
import ReferencjaImg12 from './../../assets/sycow.png';
import ReferencjaImg13 from './../../assets/kluczbork.png';
import ReferencjaImg14 from './../../assets/golesnica.png';
import ReferencjaImg15 from './../../assets/dlugoleka.png';
import ReferencjaImg16 from './../../assets/mgk.png';
import ReferencjaImg17 from './../../assets/budinwest.png';


import ArrRightImg from "../../assets/arrRight.svg";
import ArrLeftImg from "../../assets/arrLeft.svg";


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

interface Props {
}

const ReferencesSection: React.FC<Props> = (props: Props) => {

  const prevRef = useRef<any>(null);
  const nextRef = useRef<any>(null);

  return (
    <section className={`references-section`}>
      <Container>
        <MainHeader>REFERENCJE</MainHeader>
        <StandardTextBlock>
          <div className="swiperWrapper">
            <Swiper
              slidesPerView={1}
              loop={true}
              // navigation={true}
              speed={500}
              autoplay={{
                delay: 10000
              }}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: nextRef.current ? nextRef.current : undefined,
              }}
              onInit={(swiper) => {
                (swiper.params.navigation as any).prevEl = prevRef.current;
                (swiper.params.navigation as any).nextEl = nextRef.current;
                swiper.navigation.update();
              }}
            >
              <div ref={prevRef} className="cursor-pointer__left">
                <img
                  src={ArrLeftImg}
                  alt={"<-"}
                />
              </div>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg} alt="Wrocław" />
                  <br />
                  <p className="center">
                    <strong>Rejonowy Zarząd Infrastruktury we Wrocławiu</strong><br />
                  Rozdział kanalizacji sanitarnej od wód opadowych
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg2} alt="Miedzyborz" />
                  <br />
                  <p className="center">
                    <strong>Gmina Międzybórz</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg1} alt="Gmina i Miasto Oleśnica" />
                  <br />
                  <p className="center">
                    <strong>Gmina i Miasto Oleśnica</strong><br />
                    Budowa sieci wodociągowej i kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg3} alt="EKOWOD sp. z o.o w Namysłowie" />
                  <br />
                  <p className="center">
                    <strong>EKOWOD sp. z o.o w Namysłowie</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg4} alt="Gmina Oława" />
                  <br />
                  <p className="center">
                    <strong>Gmina Oława</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg5} alt="Zakład Wodociągów i Kanalizacji w Wołczynie sp. z o.o" />
                  <br />
                  <p className="center">
                    <strong>Zakład Wodociągów i Kanalizacji w Wołczynie sp. z o.o</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg6} alt="Urząd Miejski w namysłowie" />
                  <br />
                  <p className="center">
                    <strong>Urząd Miejski w Namysłowie</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg7} alt="Gmina Pokój" />
                  <br />
                  <p className="center">
                    <strong>Gmina Pokój</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg8} alt="Gmina Galewice" />
                  <br />
                  <p className="center">
                    <strong>Gmina Galewice</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg9} alt="Sycowska Gospodarka Komunalna sp. z o.o" />
                  <br />
                  <p className="center">
                    <strong>Sycowska Gospodarka Komunalna sp. z o.o</strong><br />
                    Budowa kanalizacji sanitarnej i sieci wodociągowej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg10} alt="Gmina Dobroszyce" />
                  <br />
                  <p className="center">
                    <strong>Gmina Dobroszyceo</strong><br />
                    Budowa sieci wodociągowej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg11} alt="Wodociągi Kępińskie" />
                  <br />
                  <p className="center">
                    <strong>Wodociągi Kępińskie</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg12} alt="Gmina Syców" />
                  <br />
                  <p className="center">
                    <strong>Gmina Syców</strong><br />
                    Budowa Kanalizacji Sanitarnej oraz Deszczowej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg13} alt="Gmina Kluczbork" />
                  <br />
                  <p className="center">
                    <strong>Gmina Kluczbork</strong><br />
                    Budowa kanalizacji deszczowej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg14} alt="Gmina Oleśnica" />
                  <br />
                  <p className="center">
                    <strong>Gmina Oleśnica</strong><br />
                    Budowa Mechaniczno-biologicznej oczyszczalni ścieków
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg15} alt="Gmina Długołęka" />
                  <br />
                  <p className="center">
                    <strong>Gmina Długołęka</strong><br />
                    Budowa Drogi wraz z Kanalizacją Deszczową i oświetleniem
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg16} alt="Miejska Gospodarka Komunalna sp. z o.o w Oleśnicy" />
                  <br />
                  <p className="center">
                    <strong>Miejska Gospodarka Komunalna sp. z o.o w Oleśnicy</strong><br />
                    Budowa kanalizacji sanitarnej
                </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="referenceBlock">
                  <img src={ReferencjaImg17} alt="BudInwest Przygotowanie i realizacja inwestycji sp. z o.o" />
                  <br />
                  <p className="center">
                    <strong>BudInwest Przygotowanie i realizacja inwestycji sp. z o.o</strong><br />
                    Budowa sieci kanalizacyjnych oraz sieci wodociągowej wraz z nawierzchnią placów
                </p>
                </div>
              </SwiperSlide>
              <div ref={nextRef} className="cursor-pointer__right">
                <img
                  src={ArrRightImg}
                  alt={"->"}
                />
              </div>
            </Swiper>

          </div>
        </StandardTextBlock>
      </Container>
    </section>

  )
}

export default ReferencesSection;