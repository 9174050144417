import React, { ReactNode } from "react";
import { scrollToById } from "../../../tools/handleScrollToSection";
import BgImg from "./../../../assets/wiz.jpg";
import BigLogoImg from "./../../../assets/piwiosDevBig.png";
import ContactSection from "../../../components/contact-section/contact-section";
import FullImgSection from "../../../components/full-img-section/full-img-section";
import HeroSectionDev from "../../../components/hero-section-dev/hero-section-dev";
import HeroTilesSectionDev from "../../../components/heroTiles-section-dev/heroTiles-section-dev";
import MainHeaderDev from "../../../components/main-header/main-header-dev";
import OfertaSectionDev from "../../../components/oferta-section/oferta-section-dev";
import StandardTextBlock from "../../../components/standard-text-block/standard-text-block";
import TextImgSection from "../../../components/text-img-section/text-img-section";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const DeveloperPage: React.FC<Props> = () => {

  return (
    <>
      <div className={`home-page home-page-dev`}>
        <HeroSectionDev bg={BgImg}>
          <div className="label">
            <strong>OSIEDLE</strong>
          </div>
          <h2>
            <strong>ZACISZE WSCHODNIE</strong>
          </h2>
          <div className="break"></div>
          <div className="label">już od</div>
          <h2>
            <strong>3 700 zł/m&#178;</strong>
          </h2>
          <div className="break"></div>
          <h2>≈ 135 m&#178;</h2>
          <StandardTextBlock>
            <button
              className="btn btn-dev-outline"
              onClick={()=>scrollToById("contactform", 96, true)}
            >
              ZAPYTAJ O OFERTĘ
            </button>
          </StandardTextBlock>
        </HeroSectionDev>
        <HeroTilesSectionDev />
        <FullImgSection bg={BgImg}>
          Ponad 25 lat doświadczenia w dostarczaniu nowoczesnych rozwiązań
        </FullImgSection>
        <TextImgSection img={BigLogoImg} className="smallImgOnMobile">
          <MainHeaderDev>O NAS</MainHeaderDev>
          <StandardTextBlock>
            <p>
              <strong>
                Przedsiębiorstwo Inżynierii Wodnej i Ochrony Środowiska
              </strong>
            </p>
            <br />
            <p>
              Od ponad 25 lat dostarczamy rozwiązania na rynku Inżynierii Wodnej i Ochrony Środowiska. W związku z nabytym doświadczeniem z zakresu wszelakich robót budowlanych oraz kanalizacyjnych postanowiliśmy rozpocząć działania Deweloperskie, które polepsza jakość życia ludzi mieszkających w naszych inwestycjach.
            </p>
            <br />
            <p>
              Nasza filozofia pozwala nam na stosowanie coraz to nowszych rozwiązań technologicznych w budowanych przez nas inwestycjach. Polegamy na własnym sprzęcie i zasobach ludzkich, dlatego możemy się pochwalić, że każda inwestycja jest realizowana z największą dbałością o szczegóły.
            </p>
          </StandardTextBlock>
        </TextImgSection>
        <OfertaSectionDev />
        <ContactSection />

        {/* <HistorySectionDev />
        <OfertaSectionDev />
        <ReferencesSectionDev /> */}
      </div>
    </>
  );
};

export default DeveloperPage;
