import React from "react";
import TopLink from "../top-link/top-link";
import "./action-block.scss";
import ArrowRightImg from "../../assets/arrowRight.svg";

interface Props {
  to: string;
  children: React.ReactNode;
}

const ActionBlock: React.FC<Props> = (props: Props) => {
  const { children, to } = props;
  return (
    <TopLink to={to} className={`action-block`}>
      <div className="action-block__wrapper">
        <div className={`action-block__title`}>
          {children}
        </div>
        <img src={ArrowRightImg} alt="->" />
      </div>
    </TopLink>
  )
}

export default ActionBlock;