import React from "react";
import "./prefooter.scss";
import Container from "../container/container";
import PiwiosDevImg from "./../../assets/PIWOS-deweloper.svg";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import LogosCol from "./logosCol";
import TopLink from "../top-link/top-link";
import { RoutePath } from "../../../route-paths";


interface Props {
}

const PreFooterDev: React.FC<Props> = (props: Props) => {

  return (
    <>
      <section className="prefooter prefoorerDev" >
        <Container>
          <div className="rowInfo">
            <div className="kontakt">
              <StandardTextBlock>
                <p className="title">KONTAKT</p>
                <br />
                <p>ul. Południowa</p>
                <p>56-400 Oleśnica</p>
                <br />
                <p>Telefon: 071 314 30 31</p>
                <p>Tel/fax: 071 314 30 32</p>
                <p>e-mail: deweloper@piwios.pl</p>
                <br />
                <p>NIP: 911-00-09-900</p>
                <p>REGON: 930219135</p>
                <p>PKD:4221 Z</p>
                <p>KRS: 102711</p>
              </StandardTextBlock>
            </div>
            <div className="kontakt pt-s-3">
              <StandardTextBlock>
                <p className="title">INWESTYCJE</p>
                <br />
                <p><strong>OSIEDLE ZACISZE WSCHODNIE</strong></p>
                <TopLink to={RoutePath.DEVELOPER}>
                  <img src={PiwiosDevImg} alt="FTS Beton" />
                </TopLink>
              </StandardTextBlock>
            </div>
            <LogosCol />
          </div>
        </Container>
      </section>
    </>
  )
}

export default PreFooterDev;