import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import MainHeaderDev from "../main-header/main-header-dev";
import FullMapSection from "../map-section/map-section";
import ContactForm from "./contact-form";
import "./contact-section.scss";

interface Props {}

const ContactSection: React.FC<Props> = (props: Props) => {
  return (
    <section className={`contact-section`}>
      <Container>
        <Grid>
          <Row className="contact-setion-mainRow">
            <Col size={[6, 6, 12, 12]} className="contactCol" id="contactform">
              <MainHeaderDev>NAPISZ DO NAS</MainHeaderDev>
              <ContactForm />
            </Col>
            <Col size={[6, 6, 12, 12]} className="mapCol">
              <FullMapSection src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20054.115800482923!2d17.726385037678988!3d51.075897679566594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710141c84479bef%3A0xe6d957d9ab99b013!2sWschodnia%2C%2046-100%20Namys%C5%82%C3%B3w!5e0!3m2!1spl!2spl!4v1618505214118!5m2!1spl!2spl" />
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default ContactSection;
