import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./hero-section-dev.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  bg: string;
  bgSize?: 9 | 8;
  isReverse?: boolean;
  noPt?: boolean;
  lightGradient?: boolean;

}

const HeroSectionDev: React.FC<Props> = (props: Props) => {
  const { isReverse, noPt, children, bg, bgSize, lightGradient, className } = props;
  return (
    <section className={`hero-section-dev${noPt ? " no-pt" : ""}${lightGradient ? " lightGradient" : ""}${className ? " " + className : ""}`}>
      <Container>
        <Grid>
          <Row gap={16} className={`${isReverse ? " reversed" : ""}`}>
            <Col size={[(bgSize ? bgSize : 9), 8, 8, 12]} className="imgCol">
              <div className="bgBox" style={{ backgroundImage: `url("${bg}")` }}></div>
            </Col>
            <Col size={[(bgSize ? 4 : 3), 4, 4, 12]} className="contentCol">
              <div className="contentBox">
                {children}
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default HeroSectionDev;