import React from "react";
import "./offer-block.scss";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";

interface Props {
  isReversed?: boolean;
  children: React.ReactNode;
  img: string;
}

const OfferBlock: React.FC<Props> = (props: Props) => {
  const { children, isReversed, img } = props;
  return (
    <div className="offerBlock">
      <Grid>
        <Row gap={0} className={isReversed ? "isReversed" : "isNotReversed"}>
          <Col size={[6, 6, 8, 12]} className="contentCol">
            {children}
          </Col>
          <Col size={[6, 6, 4, 12]} className="imgCol">
            <div className="bgBox" style={{ backgroundImage: `url("${img}")` }}></div>
            {/* <img src={img} alt="" /> */}
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OfferBlock;