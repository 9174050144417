import React, { } from "react";
import "./map-section.scss";

interface Props {
  maxWidth?: number,
  src: string
}

const FullMapSection: React.FC<Props> = (props: Props) => {
  const { maxWidth, src } = props;

  return (
    <section className="map-section" style={{ maxWidth: maxWidth ? maxWidth : '100%' }}>
      <iframe className="map" title="map" src={src} frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
    </section>
  )
}

export default FullMapSection;
