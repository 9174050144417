import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import PreFooter from "../../components/prefooter/prefooter";
import { select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import RulesPage from "../home-page/rules-page";
import CompanyPage from "./company-page/company-page";

const CompanySwitch: React.FC = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <Navbar isDev={false}/>
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <Switch>
          <Route exact path={RoutePath.COMPANY_RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.COMPANY} render={() => <CompanyPage />} />
        </Switch>
        <PreFooter />
        <Footer />
      </div>
    </>
  )
}

export default CompanySwitch;