import React from "react";
import { RoutePath } from "../../../route-paths";
import ActionBlock from "../action-block/action-block";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import MainHeader from "../main-header/main-header";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import "./history-section.scss";

interface Props {
}

const HistorySection: React.FC<Props> = (props: Props) => {
  return (
    <section className={`history-section`}>
      <Container>
        <MainHeader>NASZA HISTORIA</MainHeader>
        <Grid>
          <Row>
            <Col size={[6, 6, 12, 12]} className={"left-col"}>
              <StandardTextBlock>
                <p>
                Historia firmy „Przedsiębiorstwo Inżynierii Wodnej i Ochrony Środowiska Spółka z ograniczoną odpowiedzialnością” w Oleśnicy sięga roku 1965. W tym roku powstało Rejonowe Przedsiębiorstwo Melioracyjne w Oleśnicy, z którego w wyniku przekształcenia wywodzi się od 01.09.1993 roku Spółka z o.o. Przedsiębiorstwo Inżynierii Wodnej i Ochrony Środowiska w Oleśnicy
                    </p>
                <br />
                <p>
                Ponad 50 letnie doświadczenie w realizacji robót związanych z melioracją, oczyszczalniami ścieków, kanalizacją sanitarną, deszczową, wodociągami, budownictwem wodnym , drogowym itp. sprawiło, że nasza firma działa w rejonach kilku województw.
                </p>
                <br />
                <p>
                Nasze Przedsiębiorstwo buduje solidny , wykwalifikowany zespół pracowników , z którym warto budować …….. dalsze sukcesy inwestycyjne!
                </p>
              </StandardTextBlock>
            </Col>
            <Col size={[6, 6, 12, 12]} className={"left-col"}>
              <ActionBlock to={RoutePath.DEVELOPER}>
                SPRAWDŹ NASZE INWESTYCJE
              </ActionBlock>
            </Col>
          </Row>
        </Grid>
        <div className="hr" />
      </Container>
    </section>

  )
}

export default HistorySection;