import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { EmailController } from "../../../api/requests/email-controller";
import { RoutePath } from "../../../route-paths";
import { ContactFormDto } from "../../dto/contactFormDto";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import BtnCustom from "./btn-custom/btn-custom";
import CheckBox from "./checkbox/checkbox";
import "./contact-section.scss";
import FormInputOval from "./form-input-oval/form-input-oval.component";

let timeoutForm: any = undefined;

interface Props {
}

const ContactForm: React.FC<Props> = (props: Props) => {

  const [name, setname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isRuleAccepted, setIsRuleAccepted] = useState(false);
  const [isRodoAccepted, setIsRodoAccepted] = useState(false);
  const [disableButton, setdisableButton] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setdisableButton(true);
    if (!(isRuleAccepted && isRodoAccepted && email.length > 0 && message.length > 0)) {
      ToastsStore.error('Uzupełnij wszystkie pola oznaczone gwiazdką.');
      setdisableButton(false);
      return;
    }

    const contactFormDto: ContactFormDto = {
      name: name,
      message: message,
      email: email,
      checkBoxA: isRuleAccepted ? "isRuleAccepted = true" : "",
      checkBoxB: isRodoAccepted ? "isRodoAccepted = true" : "",
    }
    EmailController.SendEmail(contactFormDto).then(() => {
      setname("");
      setEmail("");
      setMessage("");
      setdisableButton(false);
      setIsRuleAccepted(false);
      setIsRodoAccepted(false);
    }).catch(() => {
      setdisableButton(false);
    });

  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutForm);
    };
  }, []);

  const handleClick = (e: any) => {
    e.stopPropagation();
  }

  return (
    <form className={`contact-form`} onSubmit={handleSubmit} >
      <Grid>
        <Row gap={16}>
          <Col size={6}>
            <FormInputOval
              name="name"
              type="text"
              label="Imię i nazwisko"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setname(e.target.value)}
            />
          </Col>
          <Col size={6}>
            <FormInputOval
              name="email"
              type="email"
              label="Email*"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
              required
            />
          </Col>
          <Col size={12}>
            <FormInputOval
              name="message"
              type="textarea"
              label="Wiadomość*"
              value={message}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              required
            />
          </Col>
          <Col size={12}>
            <CheckBox
              handleChange={setIsRuleAccepted}
              isActive={isRuleAccepted}
            >
              * Wysyłając powyższy formularz kontaktowy oświadczam, że zapoznałem/łam się z zapisami <a onClick={handleClick} href={RoutePath.DEVELOPER_RULES} target="_blank" rel="noopener noreferrer">polityki prywatności</a>
            </CheckBox>
          </Col>
          <Col size={12}>
            {/* <CheckBox
              handleChange={setIsRodoAccepted}
              isActive={isRodoAccepted}
            >
              * Lorem ipsum dolor sit amet consecue at amet consectetur, adipi icta culpa reptetur, adipisicing elit. Officiis rchi tecto! Soluta itaque dlor si est sapiente simil iqellat.
            </CheckBox> */}
          </Col>
        </Row>
      </Grid>
      <BtnCustom type="submit" disabled={disableButton}>WYŚLIJ</BtnCustom>
    </form>

  )
}

export default ContactForm;