import React from "react";
import "./main-header.scss";

interface Props {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
}

const MainHeaderDev: React.FC<Props> = (props: Props) => {
  const { children, align } = props;
  return (
    <div className={`main-header-dev ${align ? align : "left"}`} >
      {children}
      <div className="brakBar"></div>
    </div >
  )
}

export default MainHeaderDev;