import React from "react";
import Container from "../container/container";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import "./oferta-section.scss";

import ExampleImg from "./../../assets/wiz.jpg";
import OfferBlock from "../offer-block/offer-block";

interface Props {
}

const OfertaSectionDev: React.FC<Props> = () => {
  return (
    <section className={`oferta-section`}>
      <Container>
        <div className="colorContainer colorContainer-dev">
          <OfferBlock
            img={ExampleImg}
          >
            <StandardTextBlock>
              <p className="title">Ponadczasowe wzornictwo</p>
              <br />
              <p>Projektując nasze inwestycje zawsze zadajemy sobie pytanie: "Jaki budynek będzie prezentować się ponadczasowo?" - nakreślając wstępne szkice budynku kształtujemy wizję, która przeradza się w ponadczasowy projekt budowlany. Estetyka i uniwersalność naszych projektów są dla nas bardzo ważne, ponieważ kształtują one pogląd na temat inwestycji długo po wybudowaniu. Zadowolenie naszych klientów jest dla nas najważniejsze, dlatego chcemy, aby byli oni szczęśliwi przez wszystkie lata spędzone w wybudowanej przez nas inwestycji. </p>
              <br />
              <p>Dzięki zastosowaniu najnowszego wzornictwa jesteśmy w stanie zaprojektować najbardziej użyteczne wnętrze dla każdej rodziny. Nasze projekty cechuje duża przestronność wnętrza, duże przeszklenia oraz użyteczność w postaci odpowiedniego rozkładu pomieszczeń.</p>
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg}
            isReversed
          >
            <StandardTextBlock>
              <p className="title">Najnowsze technologie</p>
              <br />
              <p>Budujemy tylko z wykorzystaniem najnowszych technologii. Nigdy nie idziemy na skróty i staramy się, aby za każdym razem dostarczony przez nas budynek spełniał wzystkie wymagania naszych klientów. Udogodnienia takie jak rolety zewnętrzne, elektrycznie sterowana brama garażowa czy okna wysokiej klasy sprawią, że nasze inwestycje będą niezawodne przez lata.</p>
              <br />
              <p>Ponadto nie budujemy w pośpiechu. Każdy etap jest budowy jest starannie zaplanowany z podziałem na przygotowanie, prace wykonawcze i odpoczynek betonu, aby nie pojawiały się pęknięcia podczas mijających lat i pracy gruntu. Nasze inwestycje są budowane na płytach fundamentowych, które pozwalają na odpowiednie zaizolowanie się przed wilgocią i jak najmniejsze straty ciepła.</p>
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg}
          >
            <StandardTextBlock>
              <p className="title">Dbałość o szczegóły</p>
              <br />
              <p>Wykończenie jest jedną z najważniejszych jeśli nie najważniejszym elementem inwestycji. Dlatego też nie idziemy na skróty i zawsze staramy się wykoańczać nasze inwestycje z dbałością o detale. Projekty elewacji są przygotowywane już na wstępnym etapie prac projektowych. Następnie dostosowujemy je z aktualnym planem zagospodarowania przestrzennego i wytycznymi dotyczącymi budowy, aby nie były one odstępstwem od planowanej normy na danym osiedlu.</p>
              <br />
              <p>Wszystkie nasze inwestycje są oddawane w stanie deweloperskim, jednakże istnieje możliwość wykończenia budynku pod klucz jeśli inwestor będzie zainteresowany takim rozwiązaniem. Dzięki takiemu rozwiązaniu pozostajemy elastyczni w doborze wystroju wnętrza przez naszych klientów.</p>
            </StandardTextBlock>
          </OfferBlock>
        </div>
      </Container>
    </section>

  )
}

export default OfertaSectionDev;