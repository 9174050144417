import React from "react";
import Container from "../container/container";
import "./full-img-section.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  bg: string;
}

const FullImgSection: React.FC<Props> = (props: Props) => {
  const { children, bg, className } = props;
  return (
    <Container className="full-img-section-container">
      <div className={`full-img-section ${className ? className : ""}`} style={{ backgroundImage: `url("${bg}")` }}>
        <div className="textBox">
          {children}
        </div>
      </div >
    </Container>
  )
}

export default FullImgSection;