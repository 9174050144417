import { ToastsStore } from "react-toasts";
import { callApi } from "../api-call-service";
import { ApiMethodDto } from "../api-method-dto";
import { ContactFormDto } from "../../framework/dto/contactFormDto";
import { ApiPath } from "../api-path";

export class EmailController {
  static async SendEmail(contactFormDto: ContactFormDto): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const options = {
        body: contactFormDto,
        headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        method: "POST",
        expectedStatus: 200
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.contactForm, options)
        .then((res) => {
          if (res.details.status === options.expectedStatus) {
            ToastsStore.success('Twoja wiadomość została wysłana.');
            resolve();
          }
        }).catch((error) => {
          reject(error)
        });
    });
  }
}