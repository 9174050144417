import React from "react";
import Container from "../container/container";
import MainHeader from "../main-header/main-header";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import "./eu-section.scss";
import EuImg from "./../../assets/eu.png";
import FullImgBlock from "../fullImg-block/fullImg-block";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";

const EuSection: React.FC = () => {
  return (
    <section className={`eu-section`}>
      <Container>
        <div className="euImg">
          <FullImgBlock img={EuImg} />
        </div>
        <MainHeader>FUNDUSZE EUROPEJSKIE - Program Regionalny</MainHeader>
        <br/>
        <Grid>
          <Row>
            <Col size={[8, 8, 12]}>
              <StandardTextBlock>
                <p>
                  "Przedsiębiorstwo Inżynierii Wodnej i Ochrony Środowiska
                  Spółka z Ograniczoną Odpowiedzialnością realizuje projekt
                  dofinansowany z Funduszy Europejskich „Wzrost konkurencyjności
                  Przedsiębiorstwa Inżynierii Wodnej i Ochrony Środowiska Spółka
                  z Ograniczoną Odpowiedzialnością poprzez wdrożenie innowacji
                  procesowej i produktowej”
                </p>
                <p>
                  Celem projektu jest zakup nowoczesnego kamerowozu wyposażonego
                  w innowacyjne urządzenia, dzięki czemu wnioskodawca będzie
                  mógł wdrożyć innowacyjną usługę telewizyjnego monitoringu
                  stanu sieci kanalizacyjnej.
                </p>
                <br />
                <br />
                <p>
                  <strong>Usługa umożliwi ponadto określenie:</strong>
                </p>
                <ul>
                  <li>przebiegu trasy,</li>
                  <li>lokalizacji przyłączy na ostro,</li>
                  <li>pomiaru spadków</li>
                  <li>
                    stopnia zanieczyszczenia kanalizacji osadami dennymi lub
                    innymi,
                  </li>
                  <li>rozrostu korzeni powodujących niedrożność rur,</li>
                  <li>nieszczelności rur, przykanalików i studzienek,</li>
                  <li>lokalizacji uszkodzeń.</li>
                </ul>
              </StandardTextBlock>
            </Col>
            <Col size={[4, 4, 12]}>
              <StandardTextBlock>
                <p>
                  <strong>Możliwe będzie udokumentowanie:</strong>
                </p>
                <ul>
                  <li>odchylenia od prawidłowego położenia</li>
                  <li>zdeformowania</li>
                  <li>pęknięcia rur i ich złącza</li>
                  <li>infiltracji wód gruntowych</li>
                  <li>przeszkód utrudniających przepływ ścieków kanale</li>
                  <li>tzw. "dzikich" przyłączy.</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  Wartość całkowita projektu: 403 440,00 PLN
                  <br />
                  Wkład Funduszy Europejskich: 137 760,00 PLN
                </p>
              </StandardTextBlock>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default EuSection;
