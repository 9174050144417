import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./text-img-section.scss";


interface Props {
  img: string;
  imgMaxWidth?: number;
  imgAlt?: string;
  children: React.ReactNode;
  isReversed?: boolean;
  isMobileReversed?: boolean;
  className?: string;
}

const TextImgSection: React.FC<Props> = (props: Props) => {
  const { img, isReversed, children, imgAlt, imgMaxWidth, className, isMobileReversed } = props;

  return (
    <section className={`text-img-section${className ? " " + className : ""}`}>
      <Container>
        <Grid>
          <Row className={`${isReversed ? " reversed" : ""}${isMobileReversed ? " isMobileReversed" : ""}`}>
            <Col size={[6, 4, 4, 12]} className="imgCol">
              <img src={img} alt={imgAlt ? imgAlt : "img"} style={{ maxWidth: imgMaxWidth }} />
            </Col>
            <Col size={[6, 8, 8, 12]}>
              {children}
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>

  )
}

export default TextImgSection;