export const scrollToByRef = (section: React.MutableRefObject<any>, navOffset?: number, isSmooth?: boolean) => {
  if(isSmooth){
    let options = {
      left: 0,
      top: section.current!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65),
      behavior: 'smooth'
    } as ScrollToOptions;
    window.scrollTo(options);
  }
  else{
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65));
  }
}

export const scrollToById = (id: string, navOffset?: number, isSmooth?: boolean) => {
  let element = document.getElementById(id);
  if(element){
    if(isSmooth){
      let options = {
        left: 0,
        top: element.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65),
        behavior: 'smooth'
      } as ScrollToOptions;
      window.scrollTo(options);
    }
    else{
      window.scrollTo(0, element.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 65));
    }
  }
}