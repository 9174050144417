import React from "react";
import { RoutePath } from "../../../route-paths";
import Container from "../container/container";
import MainHeader from "../main-header/main-header";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import "./oferta-section.scss";

import ExampleImg from "./../../assets/Budowa-tloczni-pompowni-sciekow.png";
import ExampleImg1 from "./../../assets/roboty-drogowe.png";
import ExampleImg2 from "./../../assets/Roboty-Melioracyjne.png";
import ExampleImg3 from "./../../assets/Roboty-Ziemne.png";
import ExampleImg4 from "./../../assets/przeciski-pneumatyczne.png";
import ExampleImg5 from "./../../assets/Sieci-Wodociagowe.png";
import ExampleImg6 from "./../../assets/kamerowanie-sieci-kanalizacyjnych.png";
import OfferBlock from "../offer-block/offer-block";
import TopLink from "../top-link/top-link";

const OfertaSection: React.FC = () => {
  return (
    <section className={`oferta-section oferta-section-piw`}>
      <Container>
        <MainHeader>OFERTA PIWIOŚ</MainHeader>
        <div className="colorContainer">
          <OfferBlock
            img={ExampleImg}
          >
            <StandardTextBlock>
              <p className="title">Roboty wodno-kanalizacyjne</p>
              <br />
              <p>Jesteśmy w stanie zapewnić realizację robót budowla nych w zakresie zewnętrznych sieci wodociągowych i kanalizacyjnych. Ponadto możemy zaoferować przeciski dla potrzeb instalacji wod-kan, gazociągów i energetyki. Dysponujemy specjalistycznym sprzętem, który poradzi sobie z najbardziej wymagającymi zleceniami.</p>
              <br />
              <p>Nasze usługi kierujemy przede wszytskim do inwestorów, ale świadczymy je także dla osób indywidualnych.</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Dowiedz się więcej</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg1}
            isReversed
          >
            <StandardTextBlock>
              <p className="title">Roboty drogowe</p>
              <br />
              <p>Działamy na rynku robót drogowych już od ponad 50lat  roku . Bogactwo doświadczenia zebranego przed laty pozwala nam podejmować najtrudniejsze zadania z dziedziny robót drogowych. Specjalizujemy się w remontach cząstkowych, odtworzeniach nawierzchni, frezowaniu nawierzchni, nakładkach bitumicznych po robotach sieciowych  . Dysponujemy specjalistycznym sprzętem, który pozwala nam efektywnie pracować w każdych warunkach.</p>
              <br />
              <p>Przy zleceniach zajmujemy się odpowiednim przygotowaniem i zabezpieczeniem terenu, aby zachować pełne BHP naszej pracy, a także zabezpieczyć florę i faunę wokół robót. Prowadzimy wszystkie etapy prac, od wykonywania robót ziemnych po górne warstwy konstrukcyjne nawierzchni drogowych takich jak mieszanki mienralno-bitumiczne lub kosta betonowa czy też granitowa</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Dowiedz się więcej</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg2}
          >
            <StandardTextBlock>
              <p className="title">Roboty melioracyjne</p>
              <br />
              <p>Roboty i prace melioracyjne są zabiegami związanymi z regulacją cieków wodnych, wykonaniem budowli wodnych oraz zbiorników przeciwpożarowych. Specjalistyczny sprzęt pozwala nam na wykonanie robót melioracyjnych z niesamowitą precyzją i pewnością, że podczas prac nie dojdzie do niespodziewanej awarii sprzętu. Dzięki temu możemy zapewnić naszych klientów, że prace przebiegną zgodnie z wyznaczonymi terminami.</p>
              <br />
              <p>Terminowość i odpowiednie podejście do zarządzania czasem robót jest dla nas bardzo ważne, ponieważ dzięki takiemu rozwiązaniu możemy zapewnić naszych klientów o prawidłowym przebiegu prac. Melioracje zapewniają przystosowanie środowiska do potrzeb inwestora. Odpowiednio przeprowadzone prace i roboty melioracyjne są w stanie ochronić dany teren przed powodziami, zalaniami i nieprzewidzianymi podtopieniami.</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Dowiedz się więcej</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg3}
            isReversed
          >
            <StandardTextBlock>
              <p className="title">Roboty ziemne</p>
              <br />
              <p>Każdy plac budowy, czy to będzie budowa nowej drogi, nowego budynku jednorodzinnego, bloku czy pomieszczeń usługowych, potrzebuje przygotowania i odpowiedniego rozplanowania robót ziemnych. Do robót ziemnych możemy zaliczyć prace związane z wydobywaniem gruntu naturalnego, usuwanie nieprzewidzianych nasypów, kształtowanie terenu, kopanie pod fundamenty lub budowanie instalacji podziemnych.</p>
              <br />
              <p>Prace zazwyczaj polegają na odspojeniu od calizny, a więc wydobyciu materiału, jego transporcie, a także układaniu i zagęszczaniu masy ziemnej. W naszej firmie roboty ziemne są wykonywane przez wysepcjalizowaną kadrę, która od lat zapewnia wysoką jakość prac ziemnych. Dzięki doświadczeniu i integralności naszej ekipy możemy zapewnić najwyższą jakość świadczonych usług.</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Dowiedz się więcej</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg4}
          >
            <StandardTextBlock>
              <p className="title">Przeciski pneumatyczne</p>
              <br />
              <p>Przeciski pneumatyczne polegają na przeprowadzeniu przez grunt na wyznaczonym odcinku przebijaka pneumatycznego, który jednocześnie wciąga rury. Ponieważ grunt rozpychany podczas przemieszczenia nie jest w ogóle usuwany, możliwe jest tylko i wyłacznie budowanie rurociągów o średnicy zewnętrznej maksymalnie do 200 mm.</p>
              <br />
              {/* <p>Lorem ipsum dolor sit amet, consect etur adipi scing elit, sed do eius mod tempor inci didunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostr ud exerc ita tion ulla mco laboris nisi ut aliq uip ex ea comm odo conse quat. Duis aute irure dolor in repr ehende rit in volu ptate velit esse cil lum dolo re eu fugiat nulla par iatur. Excepte urnt, sunt in culpa qui officia des erunt mollit anim id est laborum.</p> */}
              <br />
              <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Zobacz naszą ofertę</button>
              </TopLink>
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg5}
            isReversed
          >
            <StandardTextBlock>
              <p className="title">Sieci wodociągowe</p>
              <br />
              <p>Wykonujemy infrastrukturę wodociągową niezbędną do doprowadzenia do odbiorcy medium (wody).</p>
              <br />
              <p>Posiadamy szeroką gamę zaplecza techniczno-sprzętowego, co pozwala nam w pełni wykonywać najbardziej skomplikowane inwestycje z zakresu sieci wodociągowych.</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Dowiedz się więcej</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
          <OfferBlock
            img={ExampleImg6}
          >
            <StandardTextBlock>
              <p className="title">Czyszczenie i monitoring sieci kanalizacyjnych</p>
              <br />
              <p>Czyścimy oraz kamerujemy sieci kanalizacyjne, sanitarne oraz deszczowe. Dzięki specjalistycznemu sprzętowi jesteśmy w stanie dokonać szczegółowych oględzin wspomnianych sieci kanalizacyjnych, sanitarnych oraz deszczowych w celu sprawdzenia stanu zużycia, szczelności i zdiagnozowania występujących problemów.</p>
              <br />
              <p>Możliwość kamerowania daje nam możliwość odpowiedniego przygotowania planu naprawczego bądź pozwala na określenie czy dana sieć wymaga wymiany.</p>
              <br />
              {/* <TopLink to={RoutePath.HOME} className="toRight">
                <button className="btn btn-piw">Zobacz naszą ofertę</button>
              </TopLink> */}
            </StandardTextBlock>
          </OfferBlock>
        </div>
      </Container>
    </section>

  )
}

export default OfertaSection;