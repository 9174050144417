import React from "react";
import Container from "../container/container";
import "./hero-section.scss";

interface Props {
  children: React.ReactNode;
  bg: string;
}

const HeroSection: React.FC<Props> = (props: Props) => {
  const { children, bg } = props;
  return (
    <section className="hero-section" style={{ backgroundImage: `url(${bg})` }}>
      <Container>
        {children}
      </Container>
    </section>
  )
}

export default HeroSection;