import React, { ReactChild } from "react";
import "./btn-custom.scss";

interface Props {
  children: ReactChild
}

const BtnCustom: React.FC<any> = (props: Props) => {
  const { children} = props

  return (
    <button className="customButton" {...props}>
      <div>{children}</div>
    </button>
  )
}

export default BtnCustom;