import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from 'history';
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import { RoutePath } from "./route-paths";
import { compose } from "redux";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import Paddings from "./framework/components/paddings/paddings";
import HomePage from "./framework/pages/home-page/home-page";
import CompanySwitch from "./framework/pages/company-switch/company-switch";
import DeveloperSwitch from "./framework/pages/developer-switch/developer-switch";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  return (
    <>
      <Paddings>
        <SlideOverlay />
        <Switch>
          <Route path={RoutePath.COMPANY} render={() => <CompanySwitch />} />
          <Route path={RoutePath.DEVELOPER} render={() => <DeveloperSwitch />} />
          <Route path={RoutePath.HOME} render={() => <HomePage />} />
        </Switch>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
        <CookiePolicyPopup />
      </Paddings>
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);