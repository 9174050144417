import React, { ReactNode } from "react";
import HeroSection from "../../../components/hero-section/hero-section";
import BgImg from "./../../../assets/piwios-25-lat.png";
import HistorySection from "../../../components/history-section/history-section";
import OfertaSection from "../../../components/oferta-section/oferta-section";
import ReferencesSection from "../../../components/references-section/references-section";
import FullMapSection from "../../../components/map-section/map-section";
import "./company-page.scss";
import EuSection from "../../../components/eu-section/eu-section";


interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const CompanyPage: React.FC<Props> = () => {
  return (
    <>
      <div className={`company-page`}>
        <HeroSection bg={BgImg}>
          <h1>Przedsiębiorstwo</h1>
          <h2>Inżynierii Wodnej i Ochrony Środowiska</h2>
        </HeroSection>
        <HistorySection />
        <OfertaSection />
        <ReferencesSection />
        <EuSection />
        <FullMapSection src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5000.065882872859!2d17.375765733176525!3d51.20004467958583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe2e4c62ffe25%3A0x5deecf2ce314e50b!2sPo%C5%82udniowa%201%2C%2056-400%20Ole%C5%9Bnica!5e0!3m2!1spl!2spl!4v1618505394141!5m2!1spl!2spl" />
      </div>
    </>
  );
};

export default CompanyPage;
