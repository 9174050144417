import React from "react";
import "./paddings.scss";

interface Props {
  children: React.ReactNode,
}

const Paddings: React.FC<Props> = (props: Props) => {
  return (
    <div className="paddings">
      {props.children}
    </div>
  )
}

export default Paddings;